import axios from "axios";
import Cookies from 'universal-cookie';
import authService from 'services/auth-service';

const jwtKey = "auth._token.local";
const refreshTokenName = 'auth.refreshToken.local';
const cookies = new Cookies();
const cookiesAccessToken = cookies.get(jwtKey);
const client = axios.create();


client.defaults.headers.Authorization = `Bearer ${cookiesAccessToken}`;
client.defaults.baseURL = `${process.env.API_URL}`;

client.interceptors.request.use(
    (config) => {
    if(cookiesAccessToken) {
        const cookiesAccessTokenNew = cookies.get(jwtKey);
        config.headers.Authorization = `Bearer ${cookiesAccessTokenNew}`;
    }
    config.withCredentials = true;
    return config;
    },
    (error) => {
    return Promise.reject(error);
});


client.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        const code = parseInt(error.response && error.response.status);
        if (code === 401 && !error.config._retry) {
            const refToken = cookies.get(refreshTokenName);
            let model = {
                refreshToken: refToken
            };
            const response = await axios.post(`${process.env.API_URL}api/auth/refresh-token`, model);
            const { accessToken, refreshToken } = response.data;
            client.defaults.headers.Authorization = `Bearer ${accessToken}`;
            client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            error.config.headers.Authorization = `Bearer ${accessToken}`;
            cookies.set('auth._token.local', accessToken, { path: '/' });
            cookies.set('auth.refreshToken.local', refreshToken, { path: '/' });
            return client(error.config);
        } else if (code === 403) {
            
        }
        return Promise.reject(error);
    }
);

export default client;