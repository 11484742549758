import axios from 'components/helpers/axios';

const url = 'api/auth';

class AuthService {
  refreshToken(refreshToken) {
    return axios.get(`${url}/refresh-token?refreshToken=${refreshToken}`);
  }
  
}

const singleton = new AuthService();
export default singleton;